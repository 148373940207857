import { cn } from "@/utils";

const MyInput = ({ label, className, ...props }) => {
  return (
    <label>
      <small>{label}</small>
      <input
        {...props}
        required
        type="text"
        className={cn(
          "w-full border border-dark-800 placeholder:text-primary-100/50 mt-2 bg-dark-900 p-4 focus:outline-none focus:ring-2 focus:ring-dark-600 text-primary-100",
          className,
        )}
      />
    </label>
  );
};
export default MyInput;

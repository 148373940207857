import LoaderIcon from "@/components/common/Loader/LoaderIcon";
import { cn } from "@/utils";
import { Transition } from "@headlessui/react";
import { Trans } from "react-i18next";

const Loader = ({ height = "h-screen", isShowing = true, className }) => {
  function getRandomElement(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }
  const texts = [
    <Trans i18nKey="loader.first">
      Please wait while we load the latest automotive information for you. Thank
      you for your patience.
    </Trans>,
    <Trans i18nKey="loader.second">
      Loading vehicles data. We appreciate your patience as we fetch the latest
      details for your automotive needs.
    </Trans>,
    <Trans i18nKey="loader.third">
      Loading in progress. We are retrieving essential car details to provide
      you with the most up-to-date information. Thank you for waiting.
    </Trans>,
    <Trans i18nKey="loader.fourth">
      Sit tight! We're working on fetching the best car options tailored just
      for you. Thanks for your understanding.
    </Trans>,
    <Trans i18nKey="loader.fifth">
      Loading... Gathering the latest car data. Your wait won't be much longer.
    </Trans>,
  ];

  return (
    <Transition
      show={isShowing}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        id="loader"
        className={cn(
          `z-[999999999] bg-dark-950 fixed top-0 left-0 w-full flex flex-col space-y-5 items-center justify-center`,
          height,
          className,
        )}
      >
        <LoaderIcon className={"w-20 h-20"} />
        <div className="text-primary-200 text-center text-sm font-bold w-9/12 lg:w-1/3">
          {getRandomElement(texts)}
        </div>
      </div>
    </Transition>
  );
};

export default Loader;

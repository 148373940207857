import { Trans } from "react-i18next";
import PhoneInput from "@/components/common/PhoneInput";
import { proccessGeoIpLookup } from "@/components/common/PhoneInput/itiUtils";
import { cn } from "@/utils";

const MyPhoneInput = ({ setFormState, className, ...props }) => {
  const availableCountries = ["ae", "sa", "om", "qa", "kw", "bh"];
  return (
    <label>
      <small>
        {/*<Trans i18nKey={"form.phoneLabel"}>Enter your phone number</Trans>*/}
        <Trans i18nKey={"form.whatsappLabel"}>Enter your WhatsApp number</Trans>
      </small>

      <PhoneInput
        setFormState={setFormState}
        inputProps={props}
        wrapClassName={cn("w-full mt-2", className)}
        itiOptions={{
          separateDialCode: true,
          initialCountry: "auto",
          onlyCountries: availableCountries,
          geoIpLookup: (callback) => {
            proccessGeoIpLookup(callback, availableCountries);
          },
        }}
        inputClassName={cn(
          "w-full border border-dark-800 focus:outline-none focus:ring-2 focus:ring-dark-600",
          "placeholder:text-primary-100/50 mt-2 bg-dark-900  p-4 text-primary-100",
        )}
      />
    </label>
  );
};

export default MyPhoneInput;

import Container from "@/components/ui/container";
import { useGetCategoriesQuery } from "@/app/api/carsApi";

import Loader from "@/components/common/Loader";

import CarsCategoriesList from "@/components/CarsCategories/CarsCategoriesList";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCategories, selectCategory } from "@/app/reducers/carReducer";

const CarsCategories = () => {
  const { slug } = useSelector(selectCategory);

  const { isLoading } = useGetCategoriesQuery({
    limit: 6,
    offset: 0,
    exclude_field: "slug",
    slug,
  });
  const { next, results: categories = [] } = useSelector(selectCategories);

  if (categories.length === 0) {
    return null;
  }
  return (
    <section className="py-16">
      <Container className="relative">
        {isLoading ? (
          <Loader height="h-full" className="absolute bg-white" />
        ) : (
          <>
            <Loader
              height="h-full"
              isShowing={isLoading}
              className="absolute bg-white"
            />
            <h3 className={"text-xl mb-5"}>
              <Trans i18nKey="carCategories.title">
                Car models are{" "}
                <span className="font-black">also available for order</span>
              </Trans>
            </h3>
            <CarsCategoriesList categories={categories} next={next} />
          </>
        )}
      </Container>
    </section>
  );
};

export default CarsCategories;

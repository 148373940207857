import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import projectReducer from "@/app/reducers/projectReducer";
import carReducer from "@/app/reducers/carReducer";
export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    project: projectReducer,
    car: carReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

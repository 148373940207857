import { t } from "i18next";
import { Trans } from "react-i18next";
import { store } from "@/app/store";
import { setUserGeoIp } from "@/app/reducers/projectReducer";

export const errorMap = [
  <Trans i18nKey={"form.validation.phoneInvalidNumber"}>Invalid number</Trans>,
  <Trans i18nKey={"form.validation.phoneInvalidCountryCode"}>
    Invalid country code
  </Trans>,
  <Trans i18nKey={"form.validation.phoneToShort"}>Too short</Trans>,
  <Trans i18nKey={"form.validation.phoneToLong"}>Too long</Trans>,
  <Trans i18nKey={"form.validation.phoneInvalidNumber"}>Invalid number</Trans>,
];

export const itiSetCustomValidity = (inputRef) => {
  const input = { ...inputRef };
  inputRef.current.setCustomValidity(
    t(
      "form.validation.phoneRequired",
      `Enter the number in international format`,
    ),
  );
  return () => {
    input.current.setCustomValidity("");
  };
};
export const itiValid = (iti, inputRef) => {
  const itiValid = iti.current.isValidNumber();
  if (itiValid) {
    inputRef.current.setCustomValidity("");
  }
  return itiValid;
};

export const itiSetErrorHandler = function (iti) {
  const errorCode = iti.current.getValidationError();
  return errorMap[errorCode];
};

export const proccessGeoIpLookup = (callback, availableCountries) => {
  fetch("https://ipapi.co/json")
    .then((res) => res.json())
    .then((data) => {
      store.dispatch(setUserGeoIp(data));
      const country = data.country_code.toLowerCase();
      return callback(
        availableCountries.includes(country) ? country : availableCountries[0],
      );
    })
    .catch(() => callback(availableCountries[0]));
};

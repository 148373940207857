import { cn } from "@/utils";
import MyInput from "@/components/ui/myInput/MyInput";
import MyPhoneInput from "@/components/ui/MyPhoneInput";
import Button from "@/components/ui/button";
import { Trans, useTranslation } from "react-i18next";
import InputsWrap from "@/components/ui/InputsWrap";
import { useEffect, useRef, useState } from "react";
import { useSendNewLeadMutation } from "@/app/api/projectApi";
import { Transition } from "@headlessui/react";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { sendLeadEvent } from "@/components/layout/Analytics";

const Form = ({
  legend,
  className,
  buttonText,
  wrapClasses,
  comment = "",
  ...props
}) => {
  const timer = useRef();
  const [isSent, setIsSent] = useState(false);
  const [sendNewLead, { isSuccess, isError, isLoading }] =
    useSendNewLeadMutation();
  const [formState, setFormState] = useState({ name: "", phone: "" });
  const { t, i18n } = useTranslation();
  const canSend =
    [formState.name, formState.phone].every(Boolean) && !isLoading;

  const [isBtnError, setIsBtnError] = useState(false);
  const inputHandler = (e) => {
    setFormState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (canSend) {
      sendNewLead({ ...formState, comment, lang: i18n.language }).then(() => {
        setIsSent(true);
        sendLeadEvent();
        setFormState({ name: "", phone: "", phoneValue: "" });
        setIsBtnError(false);
        timer.current = setTimeout(() => {
          setIsSent(false);
        }, 2000);
      });
    }
  };
  return (
    <form
      onSubmit={handleSubmit}
      {...props}
      className={cn(
        "px-4 py-5 bg-dark-950/70 lg:w-2/3 mt-5 relative",
        className,
      )}
    >
      <Transition
        show={isSuccess && isSent}
        enter="transition-opacity duration-1000"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-1000"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        unmount={true}
        as="div"
        className={cn(
          "text-primary-200 bg-dark-600",
          "w-full h-full z-50  absolute top-0  text-lg left-0",
          "flex flex-col space-y-4 p-5 text-center font-bold items-center justify-center",
        )}
      >
        <CheckBadgeIcon className="w-20 h-20" />
        The application has been successfully sent and we will contact you
        shortly
      </Transition>
      {isBtnError && !canSend && (
        <div className="text-red-500 text-sm mb-3">
          {t("form.errorClick", "Fill in all fields")}
        </div>
      )}
      {isError && (
        <div className="text-red-500 text-sm mb-3">
          {t("form.error", "Something went wrong, please try again")}
        </div>
      )}
      <legend className="mb-3 font-bold">{legend}</legend>
      <InputsWrap className={wrapClasses}>
        <MyInput
          className={cn({ "animate-shake": isBtnError })}
          label={<Trans i18nKey={"form.nameLabel"}>Enter your name</Trans>}
          required
          autoComplete="off"
          value={formState.name ?? ""}
          name="name"
          type="text"
          placeholder={t("form.namePlaceholder", "ex. John Doe")}
          onChange={(e) => inputHandler(e)}
        />
        <MyPhoneInput
          setFormState={setFormState}
          name="phone"
          className={cn({ "animate-shake": isBtnError })}
          value={formState.phoneValue ?? ""}
        />
        <div className="relative col-span-full ">
          {!canSend && (
            <div
              onClick={() => setIsBtnError(true)}
              className={cn(
                "absolute z-50 top-0 left-0 w-full h-full rounded-lg cursor-not-allowed",
              )}
            />
          )}

          <Button
            className={cn(
              "p-4 w-full relative z-10 text-lg col-span-full flex items-center justify-center",
            )}
            disabled={!canSend}
          >
            {!isLoading ? (
              buttonText
            ) : (
              <Trans i18nKey={"form.isSending"}>
                The application is being processed
              </Trans>
            )}
          </Button>
        </div>
      </InputsWrap>
    </form>
  );
};
export default Form;

import { RouterProvider } from "react-router-dom";
import router from "@/router";
import { useTranslation } from "react-i18next";
import { useGetLandingDataQuery } from "@/app/api/projectApi";
import NotFound from "@/pages/NotFound";
import { useEffect } from "react";
import Loader from "@/components/common/Loader";

function App() {
  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     const landingDataScript = document.createElement("script");
  //     landingDataScript.src = `http://localhost:8000/get_landing/?origin=http://localhost:3000`;
  //     landingDataScript.async = true;
  //     document.head.prepend(landingDataScript);
  //   }
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);
  const { isLoading, isError } = useGetLandingDataQuery(window.location.origin);
  const { i18n } = useTranslation();
  useEffect(() => {
    document.dir = i18n.dir();
    return () => {
      document.dir = "ltr";
    };
  }, [i18n]);

  if (isError) {
    return <NotFound />;
  }

  return <>{isLoading ? <Loader /> : <RouterProvider router={router} />}</>;
}

export default App;

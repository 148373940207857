import { apiSlice } from "@/app/apiSlice";
import { store } from "@/app/store";
import { setProjectData } from "@/app/reducers/projectReducer";
import getUTMParameters, { getCookie } from "@/utils";

const getRequiredData = (data) => {
  const project = store.getState().project;
  const commentData = [
    data?.comment,
    `lang:${data?.lang}`,
    project?.userGeoIp.country,
    project?.userGeoIp.city,
    project?.userGeoIp.region,
  ];
  return {
    ...data,
    project: project?.data?.uuid,
    pixel: project?.pixel,
    google_analytics: project?.ga,
    client_user_agent: navigator.userAgent,
    client_ip_address: project?.userGeoIp?.ip,
    google_client_id: sessionStorage.getItem("client_id"),
    google_session_id: sessionStorage.getItem("session_id"),
    fb_person_id: getCookie("_fbp"),
    fb_click_id: getCookie("_fbc"),
    comment: commentData.join(" \n"),
    source: JSON.stringify(getUTMParameters()),
  };
};
export const projectApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLandingData: builder.query({
      query: (origin) => `/api/landings/?origin=${encodeURIComponent(origin)}`,
      transformResponse(response) {
        store.dispatch(setProjectData(response));
        return response;
      },
    }),
    sendNewLead: builder.mutation({
      query: (data) => ({
        url: `/api/leads/`,
        method: "POST",
        body: { ...getRequiredData(data) },
      }),
      transformResponse(res) {
        // sendLeadEvent();
        return res;
      },
    }),
  }),
});
export const { useGetLandingDataQuery, useSendNewLeadMutation } =
  projectApiSlice;

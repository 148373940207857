import Footer from "@/components/common/footer";
import { useGetCategoryBySlugQuery } from "@/app/api/carsApi";
import { useParams } from "react-router-dom";
import { selectProjectUUID } from "@/app/reducers/projectReducer";
import { useSelector } from "react-redux";
import Loader from "@/components/common/Loader";
import CarsSlider from "@/components/CarsSlider";
import CarsSteps from "@/components/CarsSteps";
import CarsCategories from "@/components/CarsCategories";
import CallToAction from "@/components/CallToAction";
import CarModal from "@/components/CarModal";
import NotFound from "@/pages/NotFound";
import Header from "@/components/CarsHeader";
import TrustPilot from "@/components/TrustPilot";
const Category = () => {
  const { categorySlug } = useParams();
  const projectUUID = useSelector(selectProjectUUID);
  const { isError, isLoading } = useGetCategoryBySlugQuery({
    project: projectUUID,
    slug: categorySlug,
  });
  if (isError) {
    return <NotFound />;
  }
  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <CarsSlider />
      <CarsSteps />
      <CarsCategories />
      <TrustPilot />
      <CallToAction />
      <Footer />
      <CarModal />
    </>
  );
};
export default Category;

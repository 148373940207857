import Container from "@/components/ui/container";
import Nav from "@/components/common/nav";
import { Trans } from "react-i18next";

const Footer = () => {
  const domain = window.location.hostname;
  const currentYear = new Date().getFullYear();
  return (
    <footer className="py-10 bg-gradient-to-bl from-dark-950 to-dark-900">
      <Container>
        <Nav />
        <div className="grid text-sm lg:grid-cols-2 gap-3 mt-5 items-center text-primary-200">
          <div>
            <Trans i18nKey="footer.about">
              {{ domain }} is a group of European companies that searches,
              inspects, sells and delivers cars from the most exciting European
              customs.
            </Trans>

            <br />
          </div>
          <div className="text-right rtl:text-left">
            <Trans i18nKey="footer.copyright">
              Copyright © {{ currentYear }}. All rights reserved.
            </Trans>
          </div>
        </div>
      </Container>
    </footer>
  );
};
export default Footer;

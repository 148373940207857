import { Suspense, lazy } from "react";

import Container from "@/components/ui/container";
import { Splide } from "@splidejs/react-splide";
import { useSelector } from "react-redux";
import { nextCars, useGetCarsBySlugQuery } from "@/app/api/carsApi";
import { Trans, useTranslation } from "react-i18next";
import { selectCarsNext, selectCategory } from "@/app/reducers/carReducer";
import Loader from "@/components/common/Loader";
import "@splidejs/react-splide/css";
import { queryStringTo } from "@/utils";
import { useRef } from "react";
import SliderHeader from "@/components/common/SliderHeader";
const CarSliderTrack = lazy(() => import("./CarSliderTrack"));

const CarsSlider = () => {
  const { brand, slug } = useSelector(selectCategory);
  const { t, i18n } = useTranslation();
  const nextLink = useRef(null);
  nextLink.current = useSelector(selectCarsNext);
  const { data, isLoading } = useGetCarsBySlugQuery({
    slug: slug,
    limit: 10,
  });

  const splideOptions = {
    perPage: 4,
    direction: i18n.dir(),
    breakpoints: {
      640: {
        perPage: 1,
      },
      1024: {
        perPage: 2,
      },
    },
    gap: "1rem",
    pagination: false,
    perMove: 1,
  };

  const nextHandler = (a, slideIndex, c) => {
    if (nextLink.current) {
      const { offset } = queryStringTo(nextLink.current, ["offset", "limit"]);
      if (offset <= slideIndex + offset / 2 && slideIndex > c) {
        nextCars(nextLink.current);
      }
    }
  };

  return (
    <section className={"py-16 relative"}>
      <Suspense
        fallback={<Loader height="h-full" className="absolute bg-white" />}
      >
        <Container>
          {isLoading ? (
            <Loader height="h-full" className="absolute bg-white" />
          ) : (
            <>
              <Loader
                height="h-full"
                isShowing={isLoading}
                className="absolute bg-white"
              />
              <Splide
                key={i18n.language}
                onMove={(a, b, c) => nextHandler(a, b, c)}
                hasTrack={false}
                options={splideOptions}
                aria-label={t("carSlider.title")}
              >
                <SliderHeader
                  title={
                    <Trans i18nKey={"carSlider.title"}>
                      <strong>Сheck out other {{ brand }} offers</strong> on our{" "}
                      website
                    </Trans>
                  }
                  description={
                    <Trans i18nKey={"carSlider.description"}>
                      We're sure you'll find something special among our
                      alternatives. From high-tech models to classic and stylish
                      solutions, each car represents a unique combination of
                      innovation and elegance.
                    </Trans>
                  }
                  count={data.count}
                />

                <CarSliderTrack />
              </Splide>
            </>
          )}
        </Container>
      </Suspense>
    </section>
  );
};
export default CarsSlider;

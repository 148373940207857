import { useId } from "react";

const AdvantagesItem = ({ icon, title, index }) => {
  const id = useId();
  return (
    <li
      key={"advantage" + id + index}
      className="flex space-x-2  rtl:space-x-reverse"
    >
      {icon}
      <span>{title}</span>
    </li>
  );
};

export default AdvantagesItem;

const TrustPilotItem = ({ name, car, review }) => {
  return (
    <div className="p-5 bg-primary-50 flex flex-col items-start justify-between space-y-3">
      <div className="flex flex-col items-start space-y-3">
        <svg
          role="img"
          aria-labelledby="starRating-xp3veteso6q"
          className="h-8 inline-block"
          viewBox="0 0 251 46"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              className="fill-[#00B67A]"
              d="M0 46.330002h46.375586V0H0z"
            ></path>
            <path
              d="M39.533936 19.711433L13.230239 38.80065l3.838216-11.797827L7.02115 19.711433h12.418975l3.837417-11.798624 3.837418 11.798624h12.418975zM23.2785 31.510075l7.183595-1.509576 2.862114 8.800152L23.2785 31.510075z"
              fill="#FFF"
            ></path>
          </g>
          <g>
            <path
              className="fill-[#00B67A]"
              d="M51.24816 46.330002h46.375587V0H51.248161z"
            ></path>
            <path
              className="fill-[#00B67A]"
              d="M51.24816 46.330002h23.187793V0H51.248161z"
            ></path>
            <path
              d="M74.990978 31.32991L81.150908 30 84 39l-9.660206-7.202786L64.30279 39l3.895636-11.840666L58 19.841466h12.605577L74.499595 8l3.895637 11.841466H91L74.990978 31.329909z"
              fill="#FFF"
            ></path>
          </g>
          <g>
            <path
              className="fill-[#00B67A]"
              d="M102.532209 46.330002h46.375586V0h-46.375586z"
            ></path>
            <path
              className="fill-[#00B67A]"
              d="M102.532209 46.330002h23.187793V0h-23.187793z"
            ></path>
            <path
              d="M142.066994 19.711433L115.763298 38.80065l3.838215-11.797827-10.047304-7.291391h12.418975l3.837418-11.798624 3.837417 11.798624h12.418975zM125.81156 31.510075l7.183595-1.509576 2.862113 8.800152-10.045708-7.290576z"
              fill="#FFF"
            ></path>
          </g>
          <g>
            <path
              className="fill-[#00B67A]"
              d="M153.815458 46.330002h46.375586V0h-46.375586z"
            ></path>
            <path
              className="fill-[#00B67A]"
              d="M153.815458 46.330002h23.187793V0h-23.187793z"
            ></path>
            <path
              d="M193.348355 19.711433L167.045457 38.80065l3.837417-11.797827-10.047303-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418974zM177.09292 31.510075l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z"
              fill="#FFF"
            ></path>
          </g>
          <g>
            <path
              className="fill-[#00B67A]"
              d="M205.064416 46.330002h46.375587V0h-46.375587z"
            ></path>
            <path
              className="fill-[#00B67A]"
              d="M205.064416 46.330002h23.187793V0h-23.187793z"
            ></path>
            <path
              d="M244.597022 19.711433l-26.3029 19.089218 3.837419-11.797827-10.047304-7.291391h12.418974l3.837418-11.798624 3.837418 11.798624h12.418975zm-16.255436 11.798642l7.183595-1.509576 2.862114 8.800152-10.045709-7.290576z"
              fill="#FFF"
            ></path>
          </g>
        </svg>
        <p className="font-bold text-lg">{car}</p>
        <p className="text-sm">{review}</p>
      </div>

      <p className="text-xs">{name}</p>
    </div>
  );
};

export default TrustPilotItem;

import React from "react";
import { Trans } from "react-i18next";
import Container from "@/components/ui/container";
import TrustPilotItem from "@/components/TrustPilot/TrustPilotItem";
import logo from "./logo.svg";
var reviews = [
  {
    name: "Yasmin Al-Ghazali",
    car: "Toyota RAV4",
    review:
      "Just last week, I secured a reliable Toyota RAV4 from this dealership, and it was a fantastic experience. They fulfilled every aspect of the contract without any issues, making the car buying process stress-free. If you're in the market for a dependable and versatile SUV, don't miss out on this dealership's excellent service.",
  },
  {
    name: "Kareem Al-Mazroei",
    car: "Mercedes-Benz E-Class",
    review:
      "I recently became the proud owner of a sophisticated Mercedes-Benz E-Class from this reputable dealership, and I'm absolutely thrilled with my choice. The entire process was a breeze, and they executed the contract meticulously. If you're in the market for a high-quality car, I wholeheartedly recommend giving them a call.",
  },
  {
    name: "Zahra Al-Abadi",
    car: "Jaguar F-PACE",
    review:
      "Back in July, I decided to upgrade my vehicle and opted for a sleek Jaguar F-PACE from this dealership. The entire experience was seamless, and they delivered precisely as outlined in the contract. If you're searching for a top-tier automobile, don't hesitate to get in touch with them.",
  },
];

const TrustPilot = () => {
  return (
    <>
      <section className="py-16 relative bg-primary-100">
        <Container>
          <h3 className="text-3xl">
            <Trans i18nKey={"trustPilot.title"}>
              Testimonials from our{" "}
              <span className="font-black">best clients</span>
            </Trans>
          </h3>

          <div className="grid grid-cols-1 mt-10 lg:grid-cols-3 gap-4">
            {reviews.map((review, index) => (
              <TrustPilotItem key={index} {...review} />
            ))}
          </div>
          <div className="mt-5 text-center items-center justify-center space-x-2 rtl:space-x-reverse flex">
            <p className={"leading-none text-xs lg:text-base"}>
              <Trans i18nKey={"trustPilot.text"}>
                Rated 4.9 out of 5 based on{" "}
                <a
                  rel="noreferrer"
                  target={"_blank"}
                  href="https://trustpilot.fit/review/euroauctionauto.pro.html"
                  className="font-bold underline"
                >
                  69 reviews
                </a>{" "}
                on
              </Trans>
            </p>
            <a
              rel="noreferrer"
              target={"_blank"}
              href="https://trustpilot.fit/review/euroauctionauto.pro.html"
            >
              <img src={logo} alt={"TrustPilot"} className="h-4 lg:h-6  mb-1" />
            </a>
          </div>
        </Container>
      </section>
    </>
  );
};

export default TrustPilot;

import { createBrowserRouter } from "react-router-dom";
import Home from "@/pages/Home";
import Category from "@/pages/Category";
import NotFound from "@/pages/NotFound";
import Analytics from "@/components/layout/Analytics";

const router = createBrowserRouter([
  {
    element: <Analytics />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <Home />,
        errorElement: <NotFound />,
      },
      {
        path: "/:categorySlug",
        element: <Category />,
        layout: <Analytics />,
        errorElement: <NotFound />,
      },
    ],
  },
]);

export default router;

import Container from "@/components/ui/container";
import { Trans } from "react-i18next";

const CarsSteps = () => {
  const steps = [
    <Trans i18nKey={"carSteps.first"}>
      <strong className="font-black">
        We select a car according to your parameters &nbsp;
      </strong>
      at the auction
    </Trans>,
    <Trans i18nKey={"carSteps.second"}>
      <strong className="font-black">You make a deposit payment &nbsp;</strong>
      to participate in the auction in the amount of 50% of the cost of the car
    </Trans>,
    <Trans i18nKey={"carSteps.third"}>
      We carry out a&nbsp;
      <strong className="font-black">
        detailed check of the history of the car,
      </strong>
      &nbsp; estimate the full cost with repair and delivery
    </Trans>,
    <Trans i18nKey={"carSteps.fourth"}>
      <strong className="font-black">
        Next, we will raffle the car at the auction with your participation at
        your bid.&nbsp;
      </strong>
      You pay the cost of the lot, commission and additional expenses, if, for
      example, you need additional insurance
    </Trans>,
    <Trans i18nKey={"carSteps.fifth"}>
      After winning the auction,&nbsp;
      <strong className="font-black">
        your car will be delivered to the nearest port in Europe, &nbsp;
      </strong>
      where it will be re—photographed, decorated and loaded into a container,
      after which it will be loaded onto a ship and sent to the nearest port to
      you
    </Trans>,
    <Trans i18nKey={"carSteps.sixth"}>
      After the car arrives at the port closest to you,&nbsp;
      <strong className="font-black">we ship it by car to your city</strong>
    </Trans>,
  ];
  const stepsBlocks = steps.map((step, index) => {
    return (
      <div key={index} className="font-light">
        <span className="font-black text-lg p-2 mb-4 bg-gradient-to-bl from-dark-900 to-dark-950 h-12 w-12 flex items-center justify-center">
          {++index}
        </span>
        {step}
      </div>
    );
  });
  return (
    <section className="py-16 bg-dark-950 text-primary-300">
      <Container>
        <h3
          className={
            "text-3xl bg-gradient-to-r from-primary-500 to-primary-200 bg-clip-text text-transparent mb-10"
          }
        >
          <Trans i18nKey={"carSteps.title"}>
            <span className="font-black">Fully transparent</span> car delivery
            process
          </Trans>
        </h3>
        <div className="grid lg:grid-cols-3 gap-10">{stepsBlocks}</div>
      </Container>
    </section>
  );
};

export default CarsSteps;

import { twMerge } from "tailwind-merge";
import classNames from "classnames";
//
// export function getStaticFile(file_path) {
//   const BASE_URL = process.env.REACT_APP_BASE_URL;
//   return `${BASE_URL}/static.php?file=${file_path}`;
// }
// export function getVideoFile(file_path) {
//   const BASE_URL = process.env.REACT_APP_BASE_URL;
//   return `${BASE_URL}/video.php?file=${file_path}`;
// }

export default function getUTMParameters() {
  const urlParams = new URLSearchParams(window.location.search);

  const utmSource = urlParams.get("utm_source");
  const utmMedium = urlParams.get("utm_medium");
  const utmCampaign = urlParams.get("utm_campaign");
  const utmTerm = urlParams.get("utm_term");
  const utmContent = urlParams.get("utm_content");

  const utmObject = {
    source: utmSource,
    medium: utmMedium,
    campaign: utmCampaign,
    term: utmTerm,
    content: utmContent,
  };

  Object.keys(utmObject).forEach(
    (key) => utmObject[key] === null && delete utmObject[key],
  );

  return utmObject;
}

export function getStaticFile(file_path) {
  const BASE_URL = process.env.REACT_APP_STATIC_URL;
  return `${BASE_URL}${file_path}`;
}

export function cn(...inputs) {
  return twMerge(classNames(inputs));
}

export const transFromServer = (data, key, lang) => {
  return data[`${key}_${lang}`] ?? data[key];
};

export const queryStringTo = (path, params = []) => {
  const queryString = path.split("?")[1];
  const urlParams = new URLSearchParams(queryString);
  const paramsObj = {};
  for (const [key, value] of urlParams) {
    if (!params.includes(key)) {
      continue;
    }
    paramsObj[key] = value;
  }
  return paramsObj;
};

export const configureComment = (comment, formState, lang) => {
  return comment + `\n lang: ${lang}` ?? "";
};

export function getCookie(name) {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
  return null;
}

export const getAnalytics = () => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const pixel = urlParams.get("pixel");
  const ga = urlParams.get("ga");
  const queryObj = {
    pixel,
    ga,
  };
  // clear queryObj from null values
  Object.keys(queryObj).forEach(
    (key) => queryObj[key] === null && delete queryObj[key],
  );
  console.log(queryObj, "queryObj");
  // queryObj = { pixel: "123", ga: "456" } to string pixel=123&ga=456
  if (Object.keys(queryObj).length === 0) {
    return "";
  }
  const analytics = new URLSearchParams(queryObj).toString();
  return `&${analytics}`;
};

export const getPixel = () => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const pixelInLS = localStorage.getItem("pixel");
  const pixel = urlParams.get("pixel");
  if (!pixelInLS && pixel) {
    localStorage.setItem("pixel", pixel);
  }
  return pixelInLS ?? pixel;
};

import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import CarItem from "@/components/common/CarItem";
import Form from "@/components/common/form";
import { Trans } from "react-i18next";
import {
  removeSelectedCar,
  selectSelectedCar,
} from "@/app/reducers/carReducer";

const CarModal = () => {
  const car = useSelector(selectSelectedCar);
  const dispatch = useDispatch();

  const title = car?.title;

  const closeModal = () => {
    dispatch(removeSelectedCar());
  };
  if (!car) {
    return null;
  }
  return (
    <Transition appear show={Boolean(car)} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-dark-950/70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transition-all carModal">
                <CarItem car={car}>
                  <Form
                    className={"w-full lg:w-full p-0 "}
                    legend={
                      <Trans i18nKey={"form.modal.legendNew"}>
                        Submit an application and our specialists will send you
                        the best offer for {{ title }} via WhatsApp
                      </Trans>
                      // <Trans i18nKey={"form.modal.legend"}>
                      //   Submit an application and we will help you find your{" "}
                      //   {{ title }}
                      // </Trans>
                    }
                    wrapClasses={"grid gap-4 items-center"}
                    buttonText={
                      <Trans i18nKey={"form.modal.button"}>
                        Book your {{ title }} now
                      </Trans>
                    }
                    comment={`Car id:${car?.api_id} – I want to buy ${title}, price:€ ${car?.price}`}
                  />
                  <button
                    onClick={closeModal}
                    className={"underline w-full text-center mt-2"}
                  >
                    <Trans i18nKey={"modal.close"}>Close</Trans>
                  </button>
                </CarItem>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CarModal;

import { Trans } from "react-i18next";
import {
  CalculatorIcon,
  CameraIcon,
  ClipboardDocumentCheckIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import AdvantagesItem from "@/components/CarsHeader/Advantages/AdvantagesItemItem";

const Advantages = () => {
  const advantages = [
    {
      icon: (
        <ClipboardDocumentCheckIcon
          className="shrink-0 md:w-10 md:h-10 w-8 h-8"
          strokeWidth={1}
        />
      ),
      title: (
        <Trans i18nKey={"header.advantages.auctions"}>
          <strong>Exclusively new cars from auctions</strong> from leasing
          companies and dealer centres in Europe.
        </Trans>
      ),
    },

    {
      icon: (
        <CalculatorIcon
          className="shrink-0 md:w-10 md:h-10 w-8 h-8"
          strokeWidth={1}
        />
      ),
      title: (
        <Trans i18nKey={"header.advantages.calculation"}>
          <strong>Calculation of the cost</strong> with an accuracy of 95%
          before the redemption of the lot.
        </Trans>
      ),
    },
    {
      icon: (
        <CameraIcon
          className="shrink-0 md:w-10 md:h-10 w-8 h-8"
          strokeWidth={1}
        />
      ),
      title: (
        <Trans i18nKey={"header.advantages.camera"}>
          <strong>Additional photos will be emailed to you regularly,</strong>{" "}
          allowing you to watch the process of building your vehicle.
        </Trans>
      ),
    },
    {
      icon: (
        <TruckIcon
          className="shrink-0 md:w-10 md:h-10 w-8 h-8"
          strokeWidth={1}
        />
      ),
      title: (
        <Trans i18nKey={"header.advantages.delivery"}>
          <strong>Our careful delivery will take from 15 to 30 days,</strong>{" "}
          during which we will carefully monitor every moment.
        </Trans>
      ),
    },
  ];
  const advantagesItems = advantages.map((advantage, i) => {
    return <AdvantagesItem {...advantage} key={i} index={i} />;
  });
  return (
    <ul
      className={
        "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-3 text-primary-200"
      }
    >
      {advantagesItems}
    </ul>
  );
};

export default Advantages;

import { apiSlice } from "@/app/apiSlice";
import { store } from "@/app/store";
import {
  appendCars,
  appendCategories,
  setCategories,
  setCategory,
} from "@/app/reducers/carReducer";

const buildQuery = (queries) => {
  const project = store.getState().project.data?.uuid;
  const query = { project, ...queries };
  return Object.keys(query)
    .map((key) => key + "=" + query[key])
    .join("&");
};
export const carApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCategories: builder.query({
      query: ({ limit = 10, offset = 0, ...otherParams }) => {
        const queries = buildQuery({ limit, offset, ...otherParams });
        return `/api/categories/?${queries}`;
      },
      transformResponse(response) {
        store.dispatch(setCategories(response));
        return response;
      },
    }),
    getCategoryBySlug: builder.query({
      query: ({ slug }) => {
        const queries = buildQuery({});
        return `/api/categories/${slug}/?${queries}`;
      },
      transformResponse(response) {
        store.dispatch(setCategory(response));
        return response;
      },
    }),
    getCarsBySlug: builder.query({
      query: ({ slug, limit = 10, offset = 0 }) => {
        const queries = buildQuery({ category__slug: slug, limit, offset });
        return `/api/cars/?${queries}`;
      },
      transformResponse(response) {
        store.dispatch(appendCars(response));
        return response;
      },
    }),
    getNextCars: builder.query({
      query: (next) => {
        return next;
      },
      transformResponse(response) {
        store.dispatch(appendCars(response));
        return response;
      },
    }),
    getNextCategories: builder.query({
      query: (next) => {
        return next;
      },
      transformResponse(response) {
        store.dispatch(appendCategories(response));
        return response;
      },
    }),
  }),
});
export const {
  useGetCategoriesQuery,
  useGetCategoryBySlugQuery,
  useGetCarsBySlugQuery,
  useGetNextCarsQuery,
} = carApiSlice;

export const nextCars = (next) => {
  store.dispatch(apiSlice.endpoints.getNextCars.initiate(next));
};
export const nextCategories = (next) => {
  console.log("nextCategories", next);
  store.dispatch(apiSlice.endpoints.getNextCategories.initiate(next));
};

import { cn } from "@/utils";
import LoaderIcon from "@/components/common/Loader/LoaderIcon";
import { PhotoIcon } from "@heroicons/react/24/outline";

const CarItem = ({ car, children }) => {
  return (
    <div className="bg-dark-950 text-primary-100 shadow-lg group overflow-hidden">
      <div
        className={cn("relative overflow-hidden ", {
          "h-60 lg:h-40 carImgWrap": !car.image,
        })}
      >
        <div
          className={
            "w-full h-full flex justify-center items-center absolute z-0 "
          }
        >
          {car.image ? (
            <LoaderIcon className={"w-14 h-14"} />
          ) : (
            <PhotoIcon className={"w-14 h-14"} />
          )}
        </div>
        {car.image && (
          <img
            loading="lazy"
            className="w-full z-10 relative group-hover:scale-110 h-60 lg:h-40 object-cover object-center transition-all duration-500 carImg"
            src={`data:image/jpeg;base64, ${car?.image}`}
            alt={car.title}
          />
        )}
        <span className="flex items-end text-lg absolute font-bold px-4 pt-6 pb-2 z-10 bottom-0 left-0 h-1/2 w-full bg-gradient-to-b from-transparent via-dark-950/60 to-dark-950">
          {car.title}
        </span>
      </div>

      <div className="px-4 pb-6">
        <ul className="flex justify-between space-x-2 rtl:space-x-reverse text-sm">
          {car.characteristics.map((char) => {
            return (
              <li key={char} className="text-primary-300">
                <small>{char}</small>
              </li>
            );
          })}
        </ul>
        <div className="my-4">
          <small>Price:</small>
          <h3 className="font-black text-2xl">
            €{car.price?.toLocaleString()}
          </h3>
        </div>
        {children}
      </div>
    </div>
  );
};

export default CarItem;

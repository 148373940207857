import { cn } from "@/utils";

const HeaderOverLayer = () => {
  return (
    <div
      className={cn(
        "w-full h-full absolute top-0 left-0 z-[1] ",
        "bg-gradient-to-b from-dark-950/90 via-dark-950/60 to-dark-950",
      )}
    />
  );
};

export default HeaderOverLayer;

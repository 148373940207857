import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    data: null,
    pixel: null,
    ga: null,
    userGeoIp: null,
    ga_session_id: null,
    ga_client_id: null,
  },
  reducers: {
    setProjectData: (state, action) => {
      state.data = { ...action.payload.project };
      state.pixel = action.payload.pixel;
      state.ga = action.payload.ga;
    },
    setUserGeoIp: (state, action) => {
      state.userGeoIp = action.payload;
    },
    setGASessionId: (state, action) => {
      state.ga_session_id = action.payload;
    },
  },
});
export const { setProjectData, setUserGeoIp, setGASessionId } =
  projectSlice.actions;
export const selectProjectLogo = (state) => {
  return state.project?.data?.logo;
};

export const selectProjectDomain = (state) => {
  return state.project?.data?.domain;
};

export const selectProjectUUID = (state) => {
  return state.project?.data?.uuid;
};
export const selectProject = (state) => {
  return state.project;
};

export default projectSlice.reducer;

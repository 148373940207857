import Footer from "@/components/common/footer";
import Nav from "@/components/common/nav";
import Container from "@/components/ui/container";
import { useEffect } from "react";
import Loader from "@/components/common/Loader";
import { nextCategories, useGetCategoriesQuery } from "@/app/api/carsApi";
import NotFound from "@/pages/NotFound";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { selectCategories } from "@/app/reducers/carReducer";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";

const Home = () => {
  const { isLoading, isError } = useGetCategoriesQuery({
    limit: 10,
    offset: 0,
  });

  const { next, results: categories = [] } = useSelector(selectCategories);
  useEffect(() => {
    document.body.classList.add("bg-dark-950");
    document.body.classList.add("text-primary-100");
    return () => {
      document.body.classList.remove("bg-dark-950");
      document.body.classList.remove("text-primary-100");
    };
  }, []);
  if (isError) {
    return <NotFound />;
  }
  if (isLoading) {
    return <Loader />;
  }
  const nextHandler = (e) => {
    e.preventDefault();
    if (next) {
      console.log("next", next);
      nextCategories(next);
    }
  };
  return (
    <>
      <header className="bg-gradient-to-bl from-dark-950 to-dark-900">
        <Container>
          <Nav className={"py-5"} />
        </Container>
      </header>

      <ul className="grid grid-cols-2 md:grid-cols-3">
        {categories.map((category, index) => {
          return (
            <li key={category.slug + index} className="group">
              <a
                className="relative overflow-hidden h-52	 flex items-center"
                href={`${category.slug}`}
              >
                <img
                  className="w-full group-hover:scale-110 transition duration-700 h-full top-0 left-0 absolute object-cover object-center"
                  src={`data:image/jpeg;base64, ${category?.thumbnail}`}
                  alt={category.model}
                />
                <span className="absolute w-full h-full top-0 left-0 bg-gradient-to-br from-dark-950 via-dark-950/60"></span>
                <span className="relative  z-10 px-5  text-primary-100 font-bold w-full  flex items-center justify-between">
                  <p>
                    <small className="font-light">{category.brand}</small>
                    <span className="text-2xl block">{category.model}</span>
                  </p>
                  <button className="p-1 shrink-0 bg-dark-950 group-hover:bg-dark-800 text-primary-300 transition-colors">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1}
                      stroke="currentColor"
                      className="h-10 w-10 rtl:rotate-180"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                      />
                    </svg>
                  </button>
                </span>
              </a>
            </li>
          );
        })}
        {next && (
          <li className={"flex items-center justify-center"}>
            <button onClick={nextHandler} className="p-10 underline text-2xl">
              <Trans i18nKey="showMore">Show more</Trans>
              <ArrowLongRightIcon className="w-8 h-8 inline-block rtl:rotate-180" />
            </button>
          </li>
        )}
      </ul>

      <Footer />
    </>
  );
};
export default Home;

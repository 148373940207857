import { nextCategories } from "@/app/api/carsApi";
import CarCategoriesItem from "@/components/CarsCategories/CarCategoriesItem";
import { Trans } from "react-i18next";

const CarsCategoriesList = ({ categories, next }) => {
  const nextHandler = (e) => {
    e.preventDefault();
    if (next) {
      nextCategories(next);
    }
  };

  const categoriesList = categories.map((category) => {
    return <CarCategoriesItem key={category.slug} category={category} />;
  });
  return (
    <>
      <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6">
        {categoriesList}
      </ul>
      {next && (
        <button onClick={nextHandler} className="mt-3 underline">
          <Trans i18nKey="showMore">Show more</Trans>
        </button>
      )}
    </>
  );
};

export default CarsCategoriesList;

import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { cn } from "@/utils";

const SliderHeader = ({ title, description, count = 0 }) => {
  return (
    <div className="flex md:flex-row flex-col mb-10 justify-between md:space-x-3 rtl:space-x-reverse md:items-center">
      <div>
        <h2 className={cn("text-3xl", { "mb-2": description })}>{title}</h2>
        {description && <p className="text-sm">{description}</p>}
      </div>

      <div className="splide__arrows flex space-x-2 rtl:space-x-reverse mt-5 md:mt-0">
        <button className="p-2 bg-primary-300 transition-colors duration-700 hover:bg-primary-600 splide__arrow--prev disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:opacity-50">
          <ArrowLongRightIcon
            className="w-6 h-6 rtl:rotate-180"
            strokeWidth={2}
          />
        </button>
        {Boolean(count) && (
          <span className="flex items-center justify-center">{count}</span>
        )}
        <button className="p-2 bg-primary-300 transition-colors duration-700 hover:bg-primary-600 splide__arrow--next disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:opacity-50">
          <ArrowLongRightIcon
            className="w-6 h-6 rtl:rotate-180"
            strokeWidth={2}
          />
        </button>
      </div>
    </div>
  );
};

export default SliderHeader;

import { cn } from "@/utils";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectProjectLogo } from "@/app/reducers/projectReducer";
import { Link } from "react-router-dom";

const Nav = ({ className = "" }) => {
  const logo = useSelector(selectProjectLogo);
  const { i18n } = useTranslation();
  const isCurrentLanguage = (language) => {
    return i18n.language === language;
  };
  const changeLanguage = (event, language) => {
    event.preventDefault();
    i18n.changeLanguage(language).then(() => {
      document.dir = i18n.dir();
    });
  };

  return (
    <>
      <nav
        className={cn(
          "w-full flex py-5 justify-between space-x-2  rtl:space-x-reverse items-center",
          className,
        )}
      >
        <div
          className={"flex md:flex space-x-4 rtl:space-x-reverse  items-center"}
        >
          {logo && (
            <Link to="/">
              <img alt={"Brand"} className="h-12" src={logo} />
            </Link>
          )}
          <small className={"text-primary-100 lg:w-40"}>
            <Trans i18nKey={"nav.description"}>
              Purchase and delivery of premium cars from Europe
            </Trans>
          </small>
        </div>
        <ul
          className={
            "text-primary-100 md:flex items-center rtl:space-x-reverse hidden  space-x-2"
          }
        >
          <li className={cn({ "opacity-50": !isCurrentLanguage("en") })}>
            <a onClick={(e) => changeLanguage(e, "en")} href="/">
              <Trans i18nKey={"nav.lang.en"}>English</Trans>
            </a>
          </li>
          <li>/</li>
          <li className={cn({ "opacity-50": !isCurrentLanguage("ar") })}>
            <a onClick={(e) => changeLanguage(e, "ar")} href="/">
              <Trans i18nKey={"nav.lang.ar"}>Arabic</Trans>
            </a>
          </li>
        </ul>
        <ul
          className={
            "text-primary-100 flex items-center rtl:space-x-reverse md:hidden  space-x-2"
          }
        >
          <li className={cn({ "opacity-50": !isCurrentLanguage("en") })}>
            <a onClick={(e) => changeLanguage(e, "en")} href="/">
              <Trans i18nKey={"nav.lang.en_mobile"}>EN</Trans>
            </a>
          </li>
          <li>/</li>
          <li className={cn({ "opacity-50": !isCurrentLanguage("ar") })}>
            <a onClick={(e) => changeLanguage(e, "ar")} href="/">
              <Trans i18nKey={"nav.lang.ar_mobile"}>AR</Trans>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};
export default Nav;

import { useEffect, useRef } from "react";

const HeaderBackground = ({ videos, image, poster }) => {
  const videoRef = useRef(null);
  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.defaultMuted = true;
    videoRef.current.muted = true;
  }, []);
  if (videos.length !== 0) {
    return (
      <video
        ref={videoRef}
        className={
          "w-full h-full absolute top-0 left-0 z-[1] object-cover pointer-events-none"
        }
        controls={false}
        loop={true}
        autoPlay={true}
        playsInline={true}
        poster={poster}
      >
        {videos.map((item, i) => {
          const [[type, src]] = Object.entries(item);
          if (!src) return null;
          // data:video/mp4;base64,
          return <source key={i} src={src} type={type} />;
        })}
      </video>
    );
  }
  if (image) {
    return (
      <img
        className="w-full h-full absolute top-0 left-0 z-[1] object-cover pointer-events-none"
        alt="bg"
        src={image}
      />
    );
  }
  return <></>;
};
export default HeaderBackground;

import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectProject } from "@/app/reducers/projectReducer";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { getPixel } from "@/utils";

export const sendLeadEvent = () => {
  ReactPixel.track("Lead");
  ReactGA.event({
    category: "Lead",
    action: "Lead",
    label: "Lead",
  });
};
const initAnalytics = (project, dispatch) => {
  const pixel = getPixel() ?? project?.pixel;
  console.log("pixel", pixel);
  if (project && pixel) {
    ReactPixel.init(pixel);
    ReactPixel.pageView();
  }
  if (project && project?.ga) {
    ReactGA.initialize(project?.ga);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    ReactGA.gtag("get", project?.ga, "session_id", (ga_session_id) => {
      sessionStorage.setItem("session_id", ga_session_id);
    });
    ReactGA.gtag("get", project?.ga, "client_id", (ga_client_id) => {
      sessionStorage.setItem("client_id", ga_client_id);
    });
  }
};
const Analytics = () => {
  const dispatch = useDispatch();
  const project = useSelector(selectProject);
  useEffect(() => {
    initAnalytics(project, dispatch);
    // eslint-disable-next-line
  }, [dispatch]);

  return <Outlet />;
};

export default Analytics;

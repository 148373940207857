import { cn } from "@/utils";

const Button = ({ className, children, ...props }) => {
  //
  return (
    <button
      {...props}
      className={
        "bg-size-200 " +
        cn(
          "text-center font-medium bg-gradient-to-b from-primary-300 via-primary-500 to-primary-700 text-dark-950",
          "transition-all duration-500 ease-in-out bg-size-200 bg-pos-0 hover:bg-pos-100",
          "disabled:opacity-50 disabled:cursor-not-allowed",
          className,
        )
      }
    >
      {children}
    </button>
  );
};
export default Button;

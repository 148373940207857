import { createSlice } from "@reduxjs/toolkit";

const carSlice = createSlice({
  name: "car",
  initialState: {
    value: 0,
    categories: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    category: null,
    cars: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    selectedCar: null,
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    appendCategories: (state, action) => {
      const { count, next, previous, results } = action.payload;
      state.categories = {
        count,
        next,
        previous,
        results: [...state.categories.results, ...results],
      };
    },
    setCategory: (state, action) => {
      state.category = { ...action.payload };
    },

    appendCars: (state, action) => {
      const { count, next, previous, results } = action.payload;
      return {
        ...state,
        cars: {
          count,
          next,
          previous,
          results: [...state.cars.results, ...results],
        },
      };
    },
    setSelectedCar: (state, action) => {
      state.selectedCar = { ...action.payload };
    },
    removeSelectedCar: (state) => {
      state.selectedCar = null;
    },
  },
});
export const {
  setCategories,
  appendCategories,
  removeSelectedCar,
  setSelectedCar,
  setCategory,
  appendCars,
} = carSlice.actions;
export const selectCategory = (state) => {
  return state.car.category;
};

export const selectCategories = (state) => {
  return state.car.categories;
};
export const selectCars = (state) => {
  return state.car.cars;
};
export const selectCarsResults = (state) => {
  return state.car.cars.results;
};
export const selectCarsNext = (state) => {
  return state.car.cars.next;
};
export const selectSelectedCar = (state) => {
  return state.car.selectedCar;
};

export default carSlice.reducer;

import { useEffect, useId, useRef, useState } from "react";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { cn } from "@/utils";
import { Trans, useTranslation } from "react-i18next";
import {
  itiSetCustomValidity,
  itiSetErrorHandler,
  itiValid,
} from "@/components/common/PhoneInput/itiUtils";

const PhoneInput = ({
  setFormState,
  inputClassName,
  wrapClassName,
  itiOptions,
  inputProps,
}) => {
  const { i18n } = useTranslation();
  const inputRef = useRef(null);
  const iti = useRef(null);
  const id = useId();
  const [error, setError] = useState(null);
  const [isValid, setIiValid] = useState(false);

  useEffect(() => {
    iti.current = intlTelInput(inputRef.current, {
      ...itiOptions,
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js",
    });
    return () => {
      iti.current.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    return itiSetCustomValidity(inputRef);
  }, []);

  const handlePhoneNumberChange = function (e) {
    const isItiValid = itiValid(iti, inputRef);
    setIiValid(isItiValid);
    setError(itiSetErrorHandler(iti));
    setFormState((prevState) => {
      return {
        ...prevState,
        phone: isItiValid ? iti.current.getNumber() : null,
        phoneValue: e.target.value,
      };
    });
  };

  return (
    <>
      <div className={cn("relative", wrapClassName)}>
        <small
          className={cn(
            "absolute top-1/2 -translate-y-1/2 inline-block font-bold z-10",
            { "text-green-500": isValid },
            { "text-rose-500": !isValid },
            { "right-3": i18n.dir() === "ltr" },
            { "left-3": i18n.dir() === "rtl" },
          )}
        >
          {isValid
            ? inputProps?.value && (
                <Trans i18nKey={"form.validation.phoneValid"}>Valid</Trans>
              )
            : error}
        </small>
        <input
          {...inputProps}
          dir={i18n.dir()}
          type="tel"
          ref={inputRef}
          id={`iti-${id}`}
          className={cn(
            inputClassName,
            { "border-rose-500 focus:ring-rose-300": !isValid && error },
            { "text-right": i18n.dir() === "rtl" },
          )}
          onChange={handlePhoneNumberChange}
        />
      </div>
    </>
  );
};

export default PhoneInput;

import { ArrowRightIcon } from "@heroicons/react/24/outline";

const CarCategoriesItem = ({ category }) => {
  return (
    <li key={category.slug} className="group">
      <a
        className="relative overflow-hidden h-32	 flex items-center"
        href={`${category.slug}`}
      >
        <img
          className="w-full group-hover:scale-110 transition duration-700 h-full top-0 left-0 absolute object-cover object-center"
          src={`data:image/jpeg;base64, ${category?.thumbnail}`}
          alt={category.model}
        />
        <span className="absolute w-full h-full top-0 left-0 bg-gradient-to-br from-dark-950 via-dark-950/60"></span>
        <span className="relative  z-10 px-5  text-primary-100 font-bold w-full  flex items-center justify-between">
          <p>
            <small className="font-light">{category.brand}</small>
            <span className="text-xl block">{category.model}</span>
          </p>
          <button className="p-1 shrink-0 bg-dark-950 group-hover:bg-dark-800 text-primary-300 transition-colors">
            <ArrowRightIcon className="h-6 w-6 rtl:rotate-180" />
          </button>
        </span>
      </a>
    </li>
  );
};

export default CarCategoriesItem;

import Nav from "@/components/common/nav";
import { useSelector } from "react-redux";
import { selectCategory } from "@/app/reducers/carReducer";
import { Trans } from "react-i18next";
import HeaderBackground from "./HeaderBackground";
import HeaderOverLayer from "./HeaderOverlayer";
import Form from "@/components/common/form";
import Container from "@/components/ui/container";
import Advantages from "@/components/CarsHeader/Advantages";
import { useEffect, useRef } from "react";

const Header = () => {
  const { brand, model, ...category } = useSelector(selectCategory);
  const title = useRef();

  useEffect(() => {
    const oldTitle = document.head.title;
    document.title = title.current.textContent;
    return () => {
      document.title = oldTitle;
    };
  }, []);
  return (
    <>
      <header className="relative text-primary-100  bg-gradient-to-b from-dark-950 to-dark-800">
        <div className="container mx-auto px-4 relative lg:px-6 z-10">
          <Nav className={"py-5"} />
        </div>
        <HeaderBackground
          image={category.image}
          videos={[
            { "video/mp4": category.video_bg_mp4 },
            { "video/webm": category.video_bg_webm },
            { "video/ogg": category.video_bg_ogg },
          ]}
          poster={category?.poster}
        />
        <HeaderOverLayer />
        <div className={"container mx-auto px-4 relative lg:px-6 z-10 pt-20"}>
          <h1
            className={"text-3xl lg:text-5xl font-black text-primary-100 max-w"}
          >
            <Trans i18nKey="header.title">
              <span ref={title}>
                We will find, buy and deliver a {{ brand }} {{ model }} from
                auction in Europe at the best price on the market.
              </span>
            </Trans>
          </h1>
          <p
            className={
              "text-primary-100 lg:w-2/3 text-xl lg:text-2xl font-light mt-5"
            }
          >
            <Trans i18nKey="header.decription">
              <span className="font-bold text-primary-300">
                Free delivery from Europe
              </span>{" "}
              including customs clearance and tax refund. We will find the
              perfect car for any budget.
            </Trans>
          </p>

          <Form
            comment={`I want to buy ${brand} ${model}`}
            legend={
              <Trans i18nKey={"form.legendNew"}>
                Submit an application and our specialists will send you the best
                offer for {{ brand }} {{ model }} via WhatsApp
              </Trans>
            }
            wrapClasses={"grid gap-4 lg:grid-cols-2 items-center"}
            buttonText={
              <Trans i18nKey="form.button">
                Book your {{ brand }} {{ model }} now
              </Trans>
            }
          />
        </div>
        <Container className="relative z-10 py-20 text-sm">
          <Advantages />
        </Container>
      </header>
    </>
  );
};

export default Header;

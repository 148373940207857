import Container from "@/components/ui/container";
import Form from "@/components/common/form";
import { Trans } from "react-i18next";

import { useSelector } from "react-redux";
import { selectCategory } from "@/app/reducers/carReducer";

const CallToAction = (props) => {
  const { brand, model, ...category } = useSelector(selectCategory);

  return (
    <section className="relative py-16 overflow-hidden">
      <img
        className="absolute top-0 hidden lg:block rtl:left-0 ltr:right-0 w-1/2 h-full object-cover"
        src={category?.image}
        alt={
          <Trans i18nKey={"cta.highlighted"}>
            We will delivery the perfect car for you
          </Trans>
        }
      />
      <Container>
        <div className="grid lg:grid-cols-2 gap-10">
          <div className="flex flex-col items-start justify-center ">
            <span className="py-1 px-2 shrink-0 text-dark-950 mb-2 bg-primary-200 font-bold text-sm">
              <Trans i18nKey={"cta.highlighted"}>
                We will delivery the perfect car for you
              </Trans>
            </span>
            <h3 className={"text-4xl mb-5"}>
              <Trans i18nKey={"cta.title"}>
                <span className="font-black">
                  Your {{ brand }} at a super price
                </span>{" "}
                is ready for delivery from Europe
              </Trans>
            </h3>
            <p className="font-light mb-10 lg:mb-0">
              <Trans i18nKey={"cta.description"}>
                Fill out the form below to begin your journey to your ideal car.
                We value your choice and are ready to make the purchasing
                process simple, fun and stress-free. Let's make your choice real
                together!
              </Trans>
            </p>
          </div>
          <div className="relative flex items-center justify-center">
            <img
              className=" absolute top-0 block lg:hidden left-0 scale-x-125 h-[120%] object-cover"
              src={category?.image}
              alt="Lorem ipsum dolor sit amet,"
            />
            <Form
              comment={`I want to buy ${brand} ${model}`}
              className={
                "my-16 bg-dark-950/90 text-primary-100 w-full lg:w-9/12 relative z-10"
              }
              legend={
                <Trans i18nKey={"form.legendNew"}>
                  Submit an application and our specialists will send you the
                  best offer for {{ brand }} {{ model }} via WhatsApp
                </Trans>
                // <Trans i18nKey={"form.legend"}>
                //   Submit an application and we will help you find your{" "}
                //   {{ brand }} {{ model }}
                // </Trans>
              }
              wrapClasses={"grid gap-4 items-center"}
              buttonText={
                <Trans i18nKey={"form.button"}>
                  Book your {{ brand }} {{ model }} now
                </Trans>
              }
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CallToAction;

import { Trans } from "react-i18next";

const InputsWrap = ({ children, ...otherProps }) => {
  return (
    <div {...otherProps}>
      {children}{" "}
      <small className={"text-primary-200 text-center w-full col-span-full"}>
        <Trans i18nKey={"form.agree"}>
          I accept the terms of the offer agreement
        </Trans>
      </small>
    </div>
  );
};
export default InputsWrap;

// i18n.js
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './en/translation.json';
import translationAR from './ar/translation.json';



i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
  resources: {
    en: {
      translation: translationEN,
    },
    ar: {
      translation: translationAR
    }
  },
    fallbackLng: "en", // use en if detected lng is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag', 'path', 'subdomain'],
      cache: ['localStorage'],
      lookupLocalStorage: 'lng'

    },
    persistLanguage: true,
  });

export default i18n;

